<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
<!--							 <a-select-option :value="''">全部</a-select-option>-->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="所属影院" name="cinemaIds">
						<a-select placeholder="请选择" v-model:value="formState.cinemaIds" style="min-width: 180px;max-width: 500px;" mode="multiple"  @change="onCinemaChange">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id" > {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

                    <a-form-item class="ui-form__item" label="入账时间" name="time">
						<a-range-picker v-model:value="formState.time" :disabledDate="disabledDate"/>
					</a-form-item>

				</a-row>
				<a-row>
					<a-col :span="18">
						<a-button v-permission="['report_revenue_list_refresh']" type="primary" @click="onRefresh" style="margin-right: 20px;">刷新今日数据</a-button>
                        <span v-permission="['report_revenue_list_export']">
							<ExportReport type="revenueStatement" :searchData="searchData"></ExportReport>
						</span>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="false" :columns="columns" :dataSource="list" :scroll="{ x: 3800 }" tableLayout="auto">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'time'">
							<div>{{ transDateTime(record.startTime, 1) }}</div>
							至
							<div>{{ transDateTime(record.endTime, 1) }}</div>
						</template>
					</template>
					<template #summary v-if="list && list.length">
						<a-table-summary-row>
							<!-- <a-table-summary-cell></a-table-summary-cell> -->
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.goodsTotalPrice.toFixed(2) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.goodsTotalNum.toFixed(0) || 0}} </a-typography-text>
							</a-table-summary-cell>
							
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.numberCardPrice.toFixed(2) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.numberCardCount.toFixed(0) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.yearCardPrice.toFixed(2) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.yearCardCount.toFixed(0) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.cashTicketPrice.toFixed(2) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.cashTicketCount.toFixed(0) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.totalServiceFee.toFixed(2) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.numberCardDiff.toFixed(2) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.numberCardDiffCount.toFixed(0) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<!-- <a-table-summary-cell>
								<a-typography-text> 合计：{{total.voucherDiff.toFixed(2) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.voucherDiffCount.toFixed(0) || 0}} </a-typography-text>
							</a-table-summary-cell> -->
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.couponDiff.toFixed(2) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.couponDiffCount.toFixed(0) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.entityRechargePayment.toFixed(2) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.rechargePayment.toFixed(0) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.snackPrice.toFixed(2) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.snackCount.toFixed(0) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.scanPayment.toFixed(2) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.distributionPrice.toFixed(2) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.distributionOrderCount.toFixed(0) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.ticketDistributionPrice.toFixed(2) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.ticketDistributionOrderCount.toFixed(0) || 0}} </a-typography-text>
							</a-table-summary-cell>
							<a-table-summary-cell>
								<a-typography-text> 合计：{{total.priceCount.toFixed(2) || 0}} </a-typography-text>
							</a-table-summary-cell>
						</a-table-summary-row>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { revenueSatement, revenueRefresh } from '@/service/modules/report.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import ExportReport from '@/components/exportReport/exportReport.vue';

	export default {
		components: {
			Icon,
			ExportReport
		},
		data() {
			return {
				loading: false,
				formState: {
					time: [],
					organizationId: '',
					cinemaIds: [0]
				},
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
                columns: [{
                    title: '入账时间',
                    key: 'time',
					width: 140
                }, {
                    title: '所属影院',
                    dataIndex: 'cinemaName'
                }, {
					title: '商品销售金额（元）（不含次卡/年卡）',
					width: 180,
					dataIndex: 'goodsTotalPrice'
				}, {
					title: '商品销售单数(不含次卡/年卡)',
					width: 130,
					dataIndex: 'goodsTotalNum'
				},
				 {
                    title: '次卡销售金额(元)',
                    dataIndex: 'numberCardPrice'
                }, {
                    title: '次卡销售张数',
                    dataIndex: 'numberCardCount'
                }, {
                    title: '年卡销售金额(元)',
                    dataIndex: 'yearCardPrice'
                }, {
                    title: '年卡销售张数',
                    dataIndex: 'yearCardCount'
                }, {
                    title: '微信购票金额(元)',
                    dataIndex: 'cashTicketPrice'
                }, {
                    title: '微信购票张数',
                    dataIndex: 'cashTicketCount'
                }, {
					title: '购票退票总服务费(元)',
					dataIndex: 'totalServiceFee'
				},{
                    title: '次卡补差金额(元)',
                    dataIndex: 'numberCardDiff'
                }, {
                    title: '次卡补差张数',
                    dataIndex: 'numberCardDiffCount'
                }, 
				// {
    //                 title: '旧兑换券补差金额(元)',
    //                 dataIndex: 'voucherDiff'
    //             }, {
    //                 title: '旧兑换券补差张数',
    //                 dataIndex: 'voucherDiffCount'
    //             }, 
				{
                    title: '兑换券补差金额(元)',
                    dataIndex: 'couponDiff'
                }, {
                    title: '兑换券补差张数',
                    dataIndex: 'couponDiffCount'
                }, {
                    title: '影城会员卡充值金额(元)',
                    dataIndex: 'entityRechargePayment'
                }, {
					title: '线上会员卡充值金额(元)',
					dataIndex: 'rechargePayment'
				}, {
                   title: '卖品销售金额(元)',
				   dataIndex: 'snackPrice'
                }, {
                   title: '卖品销售份数',
				   dataIndex: 'snackCount'
                }, {
					title: '前台扫码付款',
					dataIndex: 'scanPayment'
				}, {
                    title: '商品卖品分销金额(元)',
					dataIndex: 'distributionPrice'
                }, {
					title: '商品卖品分销订单数',
					dataIndex: 'distributionOrderCount'
				}, {
                    title: '影票分销金额(元)',
					dataIndex: 'ticketDistributionPrice'
                }, {
                    title: '影票分销订单数',
					dataIndex: 'ticketDistributionOrderCount'
                }, {
                    title: '金额合计(元)',
                    dataIndex: 'priceCount'
                }],
				searchData: [],
				organizationList: [],
				cinemaAllList: [],
				total:{},
				// cinemaIds: []
			}
		},
		created(){
			this.formState.time = [this.moment().subtract(1, 'day').startOf(), this.moment().subtract(1, 'day').endOf()];
			this.onSearch();
			this.getOrganizationList();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if(this.searchData.cinemaIds && this.searchData.cinemaIds.length && this.searchData.cinemaIds.indexOf(0) === -1) {
					this.searchData.cinemaIds = this.searchData.cinemaIds.join(',');
				} else {
					this.searchData.cinemaIds = undefined;
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.searchData = {};
				this.cinemaAllList = [];
				this.formState.cinemaIds = [0];
				this.formState.time = [this.moment().subtract(1, 'day').startOf(), this.moment().subtract(1, 'day').endOf()];
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.list = [];
				 this.onSearch();
			},
			async getData() {
				if(this.searchData.time && this.searchData.time.length == 2){
					this.searchData.startTime = this.moment(this.formState.time[0].startOf('day')).unix();
					this.searchData.endTime = this.moment(this.formState.time[1].endOf('day')).unix();
				}
				delete this.searchData.time;
				const postData = {
					// page: this.pagination.current,
					// pageSize: this.pagination.pageSize,
					...this.searchData,
				}
				this.loading = true;
				try {
					let {data} = await revenueSatement(postData);
					console.log(data,'ll');
					this.loading = false;
					this.total = {};
					data.forEach((item) => {
						item.priceCount = (item.entityRechargePayment || 0)+(item.scanPayment || 0) + (item.goodsTotalPrice || 0) + item.numberCardPrice + item.yearCardPrice + item.cashTicketPrice + item.rechargePayment + item.numberCardDiff + item.voucherDiff + item.couponDiff + item.totalServiceFee + item.snackPrice - item.distributionPrice - (item.ticketDistributionPrice || 0);
						item.priceCount = Number(item.priceCount.toFixed(2));
						this.total.scanPayment = (Number(this.total.scanPayment || 0)) + (item.scanPayment || 0);
						this.total.entityRechargePayment = (this.total.entityRechargePayment || 0) + (item.entityRechargePayment || 0);
						this.total.goodsTotalPrice = (Number(this.total.goodsTotalPrice) || 0) + (item.goodsTotalPrice || 0);
						this.total.goodsTotalNum = (Number(this.total.goodsTotalNum) || 0) + (item.goodsTotalNum || 0);
						this.total.numberCardPrice = (this.total.numberCardPrice || 0) + item.numberCardPrice;
						this.total.numberCardCount = (this.total.numberCardCount || 0) + item.numberCardCount;
						this.total.yearCardPrice = (this.total.yearCardPrice || 0) + item.yearCardPrice;
						this.total.yearCardCount = (this.total.yearCardCount || 0) + item.yearCardCount;
						this.total.cashTicketPrice = (this.total.cashTicketPrice || 0) + item.cashTicketPrice;
						this.total.cashTicketCount = (this.total.cashTicketCount || 0) + item.cashTicketCount;
						this.total.totalServiceFee = (this.total.totalServiceFee || 0) + item.totalServiceFee;
						this.total.numberCardDiff = (this.total.numberCardDiff || 0) + item.numberCardDiff;
						this.total.numberCardDiffCount = (this.total.numberCardDiffCount || 0) + item.numberCardDiffCount;
						this.total.voucherDiff = (this.total.voucherDiff || 0) + item.voucherDiff;
						this.total.voucherDiffCount = (this.total.voucherDiffCount || 0) + item.voucherDiffCount;
						this.total.couponDiff = (this.total.couponDiff || 0) + item.couponDiff;
						this.total.couponDiffCount = (this.total.couponDiffCount || 0) + item.couponDiffCount;
						this.total.snackPrice = (this.total.snackPrice || 0) + item.snackPrice;
						this.total.snackCount = (this.total.snackCount || 0) + item.snackCount;
						this.total.rechargePayment = (this.total.rechargePayment || 0) + item.rechargePayment;
						this.total.distributionPrice = (this.total.distributionPrice || 0) + item.distributionPrice;
						this.total.distributionOrderCount = (this.total.distributionOrderCount || 0) + item.distributionOrderCount;
						this.total.ticketDistributionPrice = (this.total.ticketDistributionPrice || 0) + item.ticketDistributionPrice;
						this.total.ticketDistributionOrderCount = (this.total.ticketDistributionOrderCount || 0) + item.ticketDistributionOrderCount;
						this.total.priceCount = (this.total.priceCount || 0) + (item.priceCount);
					})
					this.list = data;
					// this.pagination.total = data.totalCount;
				} catch (error) {
					this.loading = false;
					// this.$message.error(error);
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				// this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async onRefresh() {
				this.loading = true;
				try {
					let ret = await revenueRefresh({});
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('刷新今日数据成功');
						if (this.formState.cinemaIds && this.formState.cinemaIds.length) {
							this.getData();
						}
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onCinemaChange(keys) {
				if (keys.indexOf(0) !== -1) {
					keys.splice(keys.indexOf(0), 1);
				}
				if (keys.length === 0) {
					keys = [0];
				}
				this.formState.cinemaIds = keys;
			},
			getCinemaId(value){
				this.cinemaIds = value;
			},
			disabledDate(current) {
                return current && current > this.moment().endOf('day');
            }
           
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
